import { ref, inject } from "vue";
import moment from "moment";

export const usePerfil = () => {
    const axios = inject("axios");
    const user = ref(null);
    const loading = ref(false);
    const quantidadeAcoes = ref("");
    const fatos = ref([])
    const askToGo = ref(null);

    const fetchPerfil = async () => {
        try {
            const userBaseUrl = `${process.env.VUE_APP_API_URL}/user`;
            const { data } = await axios.get(`${userBaseUrl}/perfil`);

            if (data.success) {
                user.value = data.user;
                askToGo.value = data.user.asktoGo.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                fatos.value = data.user.questions.filter(
                    (item) => item.difficulty === "custom"
                );
                calculateActions(data.user.questions);
            }
            else {
                user.value = "sem dados disponíveis";
            }

            loading.value = true;
        }
        catch (error) {
            console.error("Erro na requisição do perfil:", error);
        }
    };

    const calculateActions = (questions) => {
        if (Array.isArray(questions)) {
            const currentDate = moment();
            const sevenDaysAgo = moment().subtract(7, "days");
            const respondedActions = questions.filter((question) => {
                const dateResponded = moment(question.createdAt);
                return dateResponded.isBetween(sevenDaysAgo, currentDate, null, "[]");
            });
            quantidadeAcoes.value = respondedActions.length;
        }
    };

    return {
        user,
        fatos,
        loading,
        quantidadeAcoes,
        fetchPerfil,
        askToGo
    };
};
