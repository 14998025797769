import { createRouter, createWebHistory } from "vue-router";
import axios from "@/utilities/axios";

// Importações dos componentes
import Login from "@/modules/login/views/login.vue";
import Acoes from "@/modules/acao/views/acao.vue";
import Progresso from "@/modules/progresso/views/progresso.vue";
import InicioProgresso from "@/modules/progresso/components/InicioProgresso.vue";
import DiarioAcoes from "@/modules/progresso/components/DiarioAcoes.vue";
import DiarioFatos from "@/modules/progresso/components/DiarioFatos.vue";
import DiarioPerguntas from "@/modules/progresso/components/DiarioPerguntas.vue";
import Respiracao from "@/modules/respiracao/views/respiracao.vue";
import Respirar from "@/modules/respiracao/components/Respirar.vue";
import Silencio from "@/modules/silencio/views/silencio.vue";
import Cronometro from "@/modules/silencio/components/Cronometro.vue";
import Perguntas from "@/modules/perguntas/views/perguntas.vue";
import PerguntasPoderosas from "@/modules/perguntas/components/PerguntasPoderosas.vue";
import PerguntasReflexao from "@/modules/perguntas/components/PerguntasReflexao.vue";
import Faq from "@/modules/faq/views/faq.vue";
import PerguntasFrequentes from "@/modules/faq/components/PerguntasFrequentes.vue";
import compromisso from "@/modules/compromisso/views/compromisso.vue";
import Compromisso from "@/modules/compromisso/components/Compromisso.vue";
import CompromissoOk from "@/modules/compromisso/components/CompromissoOk.vue";
import Ciclos from "@/modules/ciclos-educacionais/views/ciclos.vue";
import Emocoes from "@/modules/ciclos-educacionais/components/Emocoes.vue";
import Presenca from "@/modules/ciclos-educacionais/components/Presenca.vue";
import Resiliencia from "@/modules/ciclos-educacionais/components/Resiliencia.vue";
import Felicidade from "@/modules/ciclos-educacionais/components/Felicidade.vue";

import Perfil from "@/components/Perfil.vue";
import Admin from "@/components/Admin.vue";

const routes = [
    {
        path: "",
        name: "login",
        component: Login,
        meta: { auth: false },
    },
    {
        path: "/cadastro",
        name: "cadastro",
        component: () => import("@/modules/cadastro/views/cadastro.vue"),
        meta: { auth: false },
    },
    {
        path: "/cadastro/finalizado",
        name: "cadastro-finalizado",
        component: () => import("@/modules/cadastro/views/cadastroFinalizado.vue"),
        meta: { auth: false },
    },
    {
        path: "/cadastro-trial",
        name: "cadastro-trial",
        component: () => import("@/modules/temporario/CadastroTrial.vue"),
        meta: { auth: false },
    },
    {
        path: "/morenafm",
        name: "morenafm",
        component: () => import("@/modules/temporario/CadastroTrial.vue"),
        meta: { auth: false },
    },
    {
        path: "/loading",
        name: "loading",
        component: () => import("@/components/Transition/ViewTransition.vue"),
        meta: { auth: false },
    },
    {
        path: "/boas-vindas",
        name: "boas-vindas",
        component: () => import("@/modules/geral/views/BoasVindas.vue"),
        meta: { auth: true },
    },
    {
        path: "/perfil",
        name: "perfil",
        component: Acoes,
        meta: { auth: true },
        children: [
            {
                path: "",
                name: "perfil-usuario",
                component: Perfil,
                meta: { auth: true },
            },
        ],
    },
    {
        path: "/faq",
        name: "faq",
        component: Faq,
        meta: { auth: true },
        children: [
            {
                path: "",
                name: "perguntas-frequentes",
                component: PerguntasFrequentes,
                meta: { auth: true },
            },
        ],
    },
    {
        path: "/admin",
        name: "admin",
        component: Acoes,
        meta: { auth: true, isAdmin: true },
        children: [
            {
                path: "",
                name: "admin-inicio",
                component: Admin,
                meta: { auth: true, isAdmin: true },
            },
        ],
    },
    {
        path: "/sos",
        name: "sos",
        component: Respiracao,
        meta: { auth: true },
        children: [
            {
                path: "",
                name: "respirar",
                component: Respirar,
                meta: { auth: true },
            },
        ],
    },
    {
        path: "/meditacoes",
        name: "meditacoes",
        meta: { auth: true },
    },
    {
        path: "/perguntas",
        name: "perguntas",
        component: Perguntas,
        meta: { auth: true },
        children: [
            {
                path: "",
                name: "poderosas",
                meta: { auth: true },
                component: PerguntasPoderosas,
            },
            {
                path: "dilema",
                name: "perguntas-dilema",
                meta: { auth: true },
                component: () => import("@/modules/perguntas/components/Dilema.vue"),
            },
            {
                path: "reflexao",
                name: "poderosas-reflexao",
                meta: { auth: true },
                component: () => import("@/modules/perguntas/components/PerguntasReflexao.vue"),
            },
            {
                path: "resposta/:id",
                name: "perguntas-resposta",
                meta: { auth: true },
                component: () => import("@/modules/perguntas/components/Resposta.vue"),
            },
            {
                path: "diario-reflexoes",
                name: "perguntas-diario-reflexoes",
                meta: { auth: true },
                component: () => import("@/modules/perguntas/components/DiarioReflexoes.vue"),
            },
        ],
    },
    {
        path: "/silencio",
        name: "silencio",
        component: Silencio,
        meta: { auth: true },
        children: [
            {
                path: "",
                name: "cronometro",
                component: Cronometro,
                meta: { auth: true },
            },
        ],
    },
    {
        path: "/compromisso",
        name: "compromisso",
        component: compromisso,
        meta: { auth: true },
        children: [
            {
                path: "",
                name: "frequencia",
                component: Compromisso,
                meta: { auth: true },
            },
            {
                path: "/compromisso-ok",
                name: "compromisso-aceito",
                component: CompromissoOk,
                meta: { auth: true },
            },
        ],
    },
    {
        path: "/progresso",
        name: "progresso",
        component: Progresso,
        meta: { auth: true },
        children: [
            {
                path: "",
                name: "inicio-progresso",
                component: InicioProgresso,
                meta: { auth: true },
            },
            {
                path: "/diario-acoes",
                name: "diario-de-acoes",
                component: DiarioAcoes,
                meta: { auth: true },
            },
            {
                path: "/diario-fatos",
                name: "diario-de-fatos",
                component: DiarioFatos,
                meta: { auth: true },
            },
            {
                path: "/diario-perguntas",
                name: "diario-de-perguntas",
                component: DiarioPerguntas,
                meta: { auth: true },
            },
        ],
    },
    {
        path: "/inicio-acao",
        name: "inicio-acao",
        meta: { auth: true },
        component: () => import("@/modules/acao/views/inicioAcao.vue"),
    },
    {
        path: "/acao", // MENU: Ações de bem-estar
        name: "acao",
        component: () => import("@/modules/acao/views/acao.vue"),
        meta: { auth: true },
        children: [
            {
                path: "",
                name: "escolha-acao",
                component: () => import("@/modules/acao/components/EscolhaAcao.vue"),
            },
            {
                path: ":id",
                name: "responder-acao",
                component: () => import("@/modules/acao/components/Questao.vue"),
                props: true,
                redirect: { name: "questao-1" }, // Redireciona para a primeira questão automaticamente
                children: [
                    {
                        path: "",
                        name: "questao-1",
                        component: () => import("@/modules/acao/components/respostas/resposta_1.vue"),
                    },
                    {
                        path: "2",
                        name: "questao-2",
                        component: () => import("@/modules/acao/components/respostas/resposta_2.vue"),
                    },
                    {
                        path: "3",
                        name: "questao-3",
                        component: () => import("@/modules/acao/components/respostas/resposta_3.vue"),
                    },
                    {
                        path: "4",
                        name: "questao-4",
                        component: () => import("@/modules/acao/components/respostas/resposta_4.vue"),
                    },
                ],
            },
            {
                path: "/historico-acao/:id",
                name: "historico-acao",
                component: () => import("@/modules/acao/components/Historico.vue"),
                meta: { auth: true },
                props: true,
            },            
            {
                path: "diario-acoes",
                name: "acao-diario-acoes",
                meta: { auth: true },
                component: () => import("@/modules/acao/components/DiarioAcoes.vue"),
            },
        ],
    },
    {
        path: "/fatos", // MENU: Minhas Expriências
        name: "fatos",
        component: () => import("@/modules/fatos/views/fatos.vue"),
        meta: { auth: true },
        children: [
            {
                path: "",
                name: "escolha-experiencia",
                component: () => import("@/modules/fatos/components/Fatos.vue"),
            },
            {
                path: ":id",
                name: "responder-experiencia",
                component: () => import("@/modules/fatos/components/Questao.vue"),
                props: true,
                redirect: { name: "questao-experiencia-1" }, // Redireciona para a primeira questão automaticamente
                children: [
                    {
                        path: "",
                        name: "questao-experiencia-1",
                        component: () => import("@/modules/fatos/components/respostas/resposta_1.vue"),
                    },
                    {
                        path: "2",
                        name: "questao-experiencia-2",
                        component: () => import("@/modules/fatos/components/respostas/resposta_2.vue"),
                    },
                    {
                        path: "3",
                        name: "questao-experiencia-3",
                        component: () => import("@/modules/fatos/components/respostas/resposta_3.vue"),
                    },
                    {
                        path: "4",
                        name: "questao-experiencia-4",
                        component: () => import("@/modules/fatos/components/respostas/resposta_4.vue"),
                    },
                ],
            },
            {
                path: "/historico-experiencia/:id",
                name: "historico-experiencia",
                component: () => import("@/modules/fatos/components/Historico.vue"),
                meta: { auth: true },
                props: true,
            },          
            {
                path: "diario-experiencias-pessoais",
                name: "fatos-diario-experiencias-pessoais",
                meta: { auth: true },
                component: () => import("@/modules/fatos/components/DiarioExperienciasPessoais.vue"),
            },
        ],
    },
    {
        path: "/ciclos-educacionais",
        name: "ciclos",
        component: Ciclos,
        meta: { auth: true },
        children: [
            {
                path: "",
                name: "ciclos-educacionais",
                component: () => import("@/modules/ciclos-educacionais/components/CiclosEducacionais.vue"),
                meta: { auth: true },
            },
            {
                path: "emocoes",
                name: "emocoes",
                component: Emocoes,
                meta: { auth: true },
            },
            {
                path: "presenca",
                name: "presenca",
                component: Presenca,
                meta: { auth: true },
            },
            {
                path: "resiliencia",
                name: "resiliencia",
                component: Resiliencia,
                meta: { auth: true },
            },
            {
                path: "felicidade",
                name: "felicidade",
                component: Felicidade,
                meta: { auth: true },
            },
        ],
    },
    {
        path: "/dev",
        name: "dev",
        component: () => import("@/views/dev/DevHome.vue"),
        meta: { auth: true },
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: () => import("@/modules/login/views/ResetPassword.vue"),
    },
    {
        path: "/questionarios",
        name: "questionarios",
        meta: { auth: true },
        children: [
            {
                path: "permahm",
                name: "questionarios-permahm",
                meta: { auth: true },
                children: [
                    {
                        path: "introducao",
                        name: "questionarios-permahm-introducao",
                        component: () => import("@/modules/questionarios/perfil-permahm/IntroPermahmView.vue"),
                        meta: { auth: true },
                    },
                    {
                        path: "antes-de-comecar",
                        name: "questionarios-permahm-antes-de-comecar",
                        component: () => import("@/modules/questionarios/perfil-permahm/InicioTeste.vue"),
                        meta: { auth: true },
                    },
                    {
                        path: "finalizacao/:id?",
                        name: "questionarios-permahm-finalizacao",
                        component: () => import("@/modules/questionarios/perfil-permahm/Finalizacao.vue"),
                        meta: { auth: true },
                    },
                    {
                        path: "perguntas/:type?/:id?",
                        name: "questionarios-permahm-perguntas",
                        component: () => import("@/modules/questionarios/perfil-permahm/PerfilPermahmView.vue"),
                        meta: { auth: true },
                    },
                    {
                        path: "relatorio/:id?",
                        name: "questionarios-permahm-relatorio",
                        component: () => import("@/modules/questionarios/perfil-permahm/MediaMapaBemEstar.vue"),
                        meta: { auth: true },
                    },
                    {
                        path: "mapa/:id?",
                        name: "questionarios-permahm-mapa",
                        component: () => import("@/modules/questionarios/perfil-permahm/MediaMapaBemEstar.vue"),
                        meta: { auth: true },
                    },
                ]
            },
        ],
    },
    {
        path: '/historico-bem-estar',
        component: () => import("@/modules/progresso/views/progresso.vue"),
        children: [
            {
                path: ':id?',
                name: "historico-bem-estar",
                component: () => import("@/modules/progresso/components/HistoricoBemEstar.vue"),
                meta: { auth: true },
                props: true,
            },
            {
                path: 'listagem',
                name: "historico-bem-estar-listagem",
                component: () => import("@/modules/progresso/components/HistoricoAcoesBemEstar.vue"),
                meta: { auth: true },
                props: true,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// Função para verificar se a última verificação de token foi há mais de uma hora
function shouldVerifyToken() {
    const lastVerified = localStorage.getItem("lastVerified");

    if (!lastVerified) {
        return true;
    }

    const now = new Date().getTime();
    const oneHour = 60 * 60 * 1000;
    return now - lastVerified > oneHour;
}

router.beforeEach(async (to, from, next) => {
    const token = localStorage.getItem("token");
    const exists = router.resolve(to.path).matched.length > 0;

    // console.log(`Navegando para: ${to.path}`);
    // console.log(`Token presente: ${!!token}`);

    if (!exists) {
        console.log("Rota não existe, redirecionando para login.");
        next({ name: "login" });
        return;
    }

    if (to.meta.auth) {
        if (!token) {
            console.log("Token não encontrado, redirecionando para login.");
            next({ name: "login" });
            return;
        }

        if (shouldVerifyToken()) {
            try {
                const userBaseUrl = `${process.env.VUE_APP_API_URL}/user`;
                const response = await axios.get(`${userBaseUrl}/verify-token`, {
                    headers: {
                        Authorization: token,
                    },
                });

                localStorage.setItem("lastVerified", new Date().getTime());

                if (to.meta.isAdmin) {
                    const auth = response.data.user;

                    if (auth && auth.roles && auth.roles.includes("admin")) {
                        next();
                    }
                    else {
                        console.log("Usuário não é admin, redirecionando para início-progresso.");
                        next({ name: "inicio-progresso" });
                    }
                }
                else {
                    next();
                }
            }
            catch (error) {
                console.log("Erro na verificação do token:", error);
                next({ name: "login" });
            }
        }
        else {
            next();
        }
    }
    else {
        next();
    }
});

export default router;
