<template>
    <div :class="{ hide: !loading }" class="loading-screen"></div>
    <router-view />
    <ModalAlert
        v-model="show"
        title="Hooy. Temos uma sugestão."
        @confirm="confirm"
        @click-outside="confirm"
    >
        <p>
            Para uma melhor experiência, acesse a plataforma Hooy pelo seu
            celular, ou clique em ok para continuar, recomendamos que ajuste as
            dimensões da tela.
        </p>
    </ModalAlert>

    <!-- <div v-if="!show" class="fixed inset-0 bg-black bg-opacity-50 z-50"> </div> -->
</template>

<script setup>
import { ref, onMounted } from "vue";
import ModalAlert from "@/components/Modals/ModalInicial.vue";
import "@/utilities/custom-tour/style/global.css";
import { useRouter } from "vue-router";

const show = ref(false);
const janelaAberta = ref(false);
const loading = ref(true);
const router = useRouter();

const confirm = () => {
    show.value = false;

    if (janelaAberta.value) {
        return;
    }

    if (window.opener || window.top !== window.self) {
        const newWindow = window.open(
            window.location.href,
            "_blank",
            "width=428,height=926"
        );

        if (newWindow) {
            window.self.close();
            window.close();
            newWindow.focus();
        }
    } else {
        window
            .open(window.location.href, "_blank", "width=428,height=926")
            .focus();
    }

    janelaAberta.value = true;
};

const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
};

window.addEventListener("load", () => {
    // Atualiza a visibilidade do modal baseado no tipo do dispositivo
    show.value = !isMobileDevice() && window.innerWidth > 500;
});

router.beforeEach((to, from, next) => {
    loading.value = true;
    setTimeout(() => {
        next(); // Continua a navegação após o pequeno delay (opcional)
    }, 300);
});

// Desativa o loading após a navegação completar
router.afterEach(() => {
    setTimeout(() => {
        loading.value = false; // Esmaece a tela de carregamento após 2 segundos
    }, 300);
});

onMounted(() => {
    setTimeout(() => {
        loading.value = true;
    }, 500);
});
</script>

<style>
body {
    width: 100%;
}

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 1;
    transition: opacity 0.5s ease;
    z-index: 9999;
}

.hide {
    opacity: 0;
    pointer-events: none;
}

.content {
    opacity: 0;
    animation: fadeIn 0.5s ease 2s forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
</style>
