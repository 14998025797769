import { defineStore } from 'pinia';

export const usePerguntasParaIrAlemStore = defineStore("perguntasParaIrAlemStore", {
    state: () => ({
        dilema: '',
    }),
    actions: {
        setDilema(dilema) {
            this.dilema = dilema;
        },
        clear() {
            this.dilema = '';
        }
    },
    getters: {
        getDilema: (state) => state.dilema,
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'perguntasParaIrAlemStorage',
                storage: localStorage,
            },
        ],
    },
})
