// stores/mensagemStore.js
import { defineStore } from 'pinia';

export const useCustomStyleStore = defineStore('customStyleStore', {
    state: () => ({
        color: '',
        colorName: 'orange',
    }),
    actions: {
        setColor(color) {
            this.color = color;
        },
        setColorName(colorName) {
            this.colorName = colorName;
        },
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'customStyleStorage',
                storage: localStorage,
            },
        ],
    },
});
