<template>
<div class="mx-7 flex flex-col items-center justify-center">
    <div class="flex flex-col items-center justify-center">
        <h1 class="text-[#2F8E87] text-[26px] text-center font-bold-segoe leading-6 my-5">
            {{ props.title }}
        </h1>
    </div>
    <div class="w-full">
        <div v-if="audios.length">
            <div v-for="(item, index) in audios" class="border-t-2 w-full flex gap-3 py-1 my-1 pt-3" :key="index">
                <!-- Coluna 1 -->
                <div class="coluna-1">
                    <button @click="togglePlayPause(index)" :id="`play-${index}`">
                        <play v-if="!isPlaying[index]" />
                        <pause v-else />
                    </button>
                </div>

                <!-- Coluna 2 -->
                <div class="coluna-2 flex-grow">
                    <div v-if="isBufferingComplete[index] && (isPlaying[index] || hasStarted[index])"
                        class="w-full flex flex-col">
                        <div class="flex items-center w-full mt-3">
                            <!-- Barra de progresso personalizada -->
                            <input type="range" class="audio-progress flex-grow" min="0" :max="audioDurations[index]"
                                :value="audioProgress[index]" @input="seekAudio(index, $event)"
                                @change="resumeAudio(index)">
                        </div>

                        <div class="flex justify-between w-full mt-1 text-[9px] text-[#9e9e9e]">
                            <span>{{ formatTime(audioProgress[index]) }}</span>
                            <span>{{ formatTime(audioDurations[index]) }}</span>
                        </div>
                    </div>

                    <span v-else class="nome-audio font-bold-segoe text-[#37A79F] mt-0.5"> {{ item.nome }}</span>
                </div>

                <!-- Coluna 3 -->
                <div v-if="isBufferingComplete[index] && (isPlaying[index] || hasStarted[index])"
                    class="coluna-3 flex items-center">
                    <button @click="changeSpeed(index)"
                        class="me-1 text-[12px] text-[#FFFFFF] bg-[#37A79F] font-bold-segoe inline-flex items-center rounded-[15px] px-2 ring-1 ring-inset">
                        {{ playbackRates[index] }}x
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
    import { ref, defineProps, onMounted } from 'vue';
    import audioList from './data/audio-map.json';
    import Play from '@/assets/icons/commands/Play.vue';
    import Pause from '@/assets/icons/commands/Pause.vue';

    const props = defineProps({
        audioType: String,
        title: String,
    });

    const audioBaseSrc = ref(process.env.VUE_APP_URL);
    const audios = ref([]);
    const audioInstances = ref([]);
    const isPlaying = ref([]);
    const hasStarted = ref([]);
    const audioProgress = ref([]);
    const audioDurations = ref([]);
    const playbackRates = ref([]);
    const isSeeking = ref([]);
    const isBufferingComplete = ref([]);

    const fetchAndCacheAudio = async (url, index) => {
        // Função para buscar e cachear o áudio
        const cacheName = 'audio-cache';
        const cache = await caches.open(cacheName);
        const cachedResponse = await cache.match(url);

        if (cachedResponse) {
            return cachedResponse.url;
        }

        const response = await fetch(url);
        await cache.put(url, response.clone());
        isBufferingComplete.value[index] = true;
        return response.url;
    };

    const loadAudioMap = async (audioType) => {
        const selectedAudioType = await audioList.categorias.filter(categoria => categoria.tipo === audioType);
        audios.value = selectedAudioType[0].audios;

        audios.value.forEach(async (_, index) => {
            isPlaying.value[index] = false;
            hasStarted.value[index] = false;
            audioProgress.value[index] = 0;
            audioDurations.value[index] = 0;
            playbackRates.value[index] = 1;
            isSeeking.value[index] = false;
            isBufferingComplete.value[index] = false;

            const cachedUrl = await fetchAndCacheAudio(`${audioBaseSrc.value}${_.caminho}`, index);

            const audio = new Audio(cachedUrl);
            audio.preload = "auto";

            audio.addEventListener('canplaythrough', () => {
                isBufferingComplete.value[index] = true;
            });
            audio.addEventListener('timeupdate', () => updateProgress(index));
            audio.addEventListener('ended', () => resetAudio(index));

            // Adiciona tratamento de erro ao tentar retomar a reprodução
            audioInstances.value[index] = audio;
        });
    };

    const togglePlayPause = (index) => {
        const audio = audioInstances.value[index];

        if (isPlaying.value[index]) {
            audio.pause();
            isPlaying.value[index] = false;
        }
        else {
            stopOthers(index);

            if (isBufferingComplete.value[index]) {
                playAudioWithRecovery(audio, index);
                isPlaying.value[index] = true;
                hasStarted.value[index] = true;
            }
        }
    };

    const playAudioWithRecovery = (audio, index) => {
        // Função para tentar reproduzir o áudio com recuperação em caso de erro
        audio.play().catch((error) => {
            console.error("Erro ao reproduzir o áudio:", error);

            if (error.name === "AbortError") {
                console.log("Tentando recarregar o áudio...");
                reloadAudioFromCache(index);
            }
        });
    };

    // Função para recarregar o áudio do cache
    const reloadAudioFromCache = async (index) => {
        const audioUrl = await fetchAndCacheAudio(`${audioBaseSrc.value}${audios.value[index].caminho}`, index);
        const newAudio = new Audio(audioUrl);
        newAudio.preload = "auto";
        newAudio.currentTime = 0;

        newAudio.addEventListener('canplaythrough', () => {
            isBufferingComplete.value[index] = true;
        });
        newAudio.addEventListener('timeupdate', () => updateProgress(index));
        newAudio.addEventListener('ended', () => resetAudio(index));

        audioInstances.value[index] = newAudio;
        playAudioWithRecovery(newAudio, index);
    };

    const stopOthers = (index) => {
        audioInstances.value.forEach((audio, i) => {
            if (i !== index) {
                audio.pause();
                audio.currentTime = 0;
                isPlaying.value[i] = false;
                audioProgress.value[i] = 0;
                hasStarted.value[i] = false;
            }
        });
    };

    const updateProgress = (index) => {
        const audio = audioInstances.value[index];

        if (!isSeeking.value[index]) {
            audioProgress.value[index] = audio.currentTime;
            audioDurations.value[index] = audio.duration;
        }
    };

    const seekAudio = (index, event) => {
        isSeeking.value[index] = true;
        audioProgress.value[index] = event.target.value;
    };

    const resumeAudio = (index) => {
        const audio = audioInstances.value[index];
        audio.currentTime = audioProgress.value[index];

        if (isPlaying.value[index]) {
            playAudioWithRecovery(audio, index);
        }

        isSeeking.value[index] = false;
    };

    const changeSpeed = (index) => {
        const audio = audioInstances.value[index];
        playbackRates.value[index] = playbackRates.value[index] === 1 ? 1.5 : 1;
        audio.playbackRate = playbackRates.value[index];
    };

    const resetAudio = (index) => {
        isPlaying.value[index] = false;
        audioProgress.value[index] = 0;
        hasStarted.value[index] = false;
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60).toString().padStart(2, '0');
        return `${minutes}:${secs}`;
    };

    onMounted(() => {
        loadAudioMap(props.audioType);
    });
</script>
<style scoped>

    /* Estilização do input type="range" */
    input[type="range"].audio-progress {
        -webkit-appearance: none;
        width: 100%;
        height: 2px;
        /* Altura da barra */
        background: #d3d3d3;
        /* Cor da barra de fundo */
        outline: none;
        margin: 0;
        padding: 0;
    }

    input[type="range"].audio-progress::-webkit-slider-runnable-track {
        height: 2px;
        background-color: #d3d3d3;
    }

    input[type="range"].audio-progress::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 12px;
        /* Largura do botão de controle */
        height: 12px;
        /* Altura do botão de controle */
        background-color: #37A79F;
        /* Cor do botão de controle */
        border-radius: 50%;
        cursor: pointer;
        margin-top: -5px;
        /* Centraliza o botão na barra */
    }

    input[type="range"].audio-progress::-moz-range-track {
        height: 2px;
        background-color: #d3d3d3;
    }

    input[type="range"].audio-progress::-moz-range-progress {
        height: 2px;
        background-color: #37A79F;
        /* Cor da parte já reproduzida */
    }

    input[type="range"].audio-progress::-moz-range-thumb {
        width: 12px;
        height: 12px;
        background-color: #37A79F;
        border-radius: 50%;
        cursor: pointer;
    }

    input[type="range"].audio-progress::-ms-fill-lower {
        background-color: #37A79F;
    }

    input[type="range"].audio-progress::-ms-fill-upper {
        background-color: #d3d3d3;
    }

    input[type="range"].audio-progress::-ms-thumb {
        width: 12px;
        height: 12px;
        background-color: #37A79F;
        border-radius: 50%;
        cursor: pointer;
    }
</style>
