<template>
<Loader v-if="isLoading" />
<div class="bg-gray-100 flex items-start justify-center min-h-screen bg-fixed">
    <div class=" container mx-auto h-full overflow-hidden">
        <div class>
            <div class="container flex flex-col items-center justify-between w-full h-full mt-10 ">
                <div class="form-login mt-[35%]">
                    <h1 class="font-segoe-ui-black text-center text-white mt-[36%] mb-[34px]">
                        Acesse Seu Autoconhecimento
                    </h1>
                    <login-form @show-modal="showModal" @loading="setLoading" />
                    <div class="flex flex-col items-center">
                        <img src="@/assets/icons/hooy/hooy-cut-white.svg" alt="" class="w-[100px] mt-[10%]">
                        <img src="@/assets/logos/voceautentico-branco.png" alt="" class="mt-[10%]">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<modal-alert v-model="show" title="Atenção" @confirm="confirm">
    <p v-html="contentModal"></p>
</modal-alert>


</template>

<script setup>
import ModalAlert from '@/components/Modals/ModalAlert.vue';
import LoginForm from '@/modules/login/components/LoginForm.vue';
import Loader from '@/modules/login/components/Loader.vue';
import { ref } from 'vue';

const show = ref(false);
const contentModal = ref('');
const isLoading = ref(false);
const confirm = () => {
    show.value = false;
};

const teste = (message) => {
    contentModal.value = message;
    show.value = true;
};

const showModal = (message) => {
    contentModal.value = message;
    show.value = true;
};

const setLoading = (loading) => {
    isLoading.value = loading;
};
</script>

<style scoped>
.container {
    max-width: 100%;
}

.bg-fixed {
    background-image: url('../../../assets/login-bg-1080-1920-c.jpg');
    background-size: cover;
    background-position: bottom;
    height: 100%;
}

.form-login {
    z-index: 5;
}

.background-login {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
}

.background-image {
    background-image: url('@/assets/login-bg-1080-1920.png');
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    margin-top: 150px;
    object-fit: cover;
    object-position: center;
}

.font-segoe-ui-black {
    font-family: 'Segoe UI Black', sans-serif;
    font-size: 30px;
    line-height: 0.8;
}
</style>
