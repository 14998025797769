<template>
<div class="mx-[28px]" v-if="loading">
    <div class="text-center my-2">
        <h1 class="font-bold-segoe text-[#FD7862] text-[26px] mb-3">Progresso</h1>
        <p class="text-[#FD7862] font-bold-segoe text-[15px]">Seu Compromisso neste momento:</p>
        <p class="text-[#FD7862] font-bold-segoe text-[13px] mb-3">
            <span v-if="user.frequence <= 1">É</span><span v-else>São</span> de {{ user.frequence }} <span
                v-if="user.frequence <= 1">ação</span>
            <span v-else>ações</span> por semana.
        </p>
        <span class="font-segoe-ui text-[16px] text-[#727376]">
            Você realizou
            <strong>{{ quantidadeAcoes }} <span v-if="quantidadeAcoes <= 1">ação</span> <span v-else>ações</span> nessa
                semana</strong>.
        </span>
    </div>
    <div class="flex flex-col items-center justify-center">
        <img src="@/assets/icons/avatars/grafico.svg" alt="" class="mb-2 size-[90%]">
        <img src="@/assets/icons/arrows/arrow-right.svg" alt="" class="mb-2 size-[90%]">
    </div>
    <div class="flex flex-col items-center justify-center">
        <router-link to="/acao/diario-acoes"
            class="bg-[#f36e21] w-[95%] text-white font-bold-segoe text-[13px] p-3 m-1 rounded-md text-center">
            diário de ações de bem-estar
        </router-link>
        <router-link to="/fatos/diario-experiencias-pessoais"
            class="bg-[#f34b21] w-[95%] text-white font-bold-segoe text-[13px] p-3 m-1 rounded-md text-center">
            diário de experiências pessoais
        </router-link>
        <router-link to="/perguntas/diario-reflexoes"
            class="bg-[#2f8e87] w-[95%] text-white font-bold-segoe text-[13px] p-3 m-1 rounded-md text-center">
            diário de reflexões para ir além
        </router-link>
        <router-link to="/historico-bem-estar"
            class="bg-[#FD7062] w-[95%] text-white font-bold-segoe text-[13px] p-3 m-1 rounded-md text-center">
            diagnóstico do bem-estar
        </router-link>
        <div class="mb-6">
            &nbsp;
        </div>
    </div>
</div>
</template>

<script setup>
    import { onMounted, computed } from 'vue';
    import { usePerfil } from '@/modules/progresso/composables/usePerfil';
    import { useAuthStore } from "@/store";

    const { user, loading, quantidadeAcoes, fetchPerfil } = usePerfil();
    const auth = useAuthStore();

    const exibirApenasParaAdmin = computed(() => {
        return auth.roles && auth.roles.includes('admin');
    });

    onMounted(() => {
        fetchPerfil();
    });
</script>

<style scoped></style>
