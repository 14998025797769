<template>
<div class="container my-5 px-8">
    <h1 class="font-bold-segoe text-center text-[26px] text-[#F37335] leading-none my-5">
        FAQ - Perguntas Frequentes
    </h1>
    <div class="text-center ">
        <div class="relative w-[100%] mx-auto">
            <InputText
                class="border border-[#F37335] text-[#F37335] rounded-[13px] w-[100%]  outline-none h-[41px] my-3"
                type="text" v-model="pergunta" />
            <i
                class="pi pi-search absolute right-[3%] top-1/2 transform -translate-y-1/2 text-[#F37335] font-extrabold text-[20px]"></i>
        </div>
    </div>
    <div id="lista-de-perguntas" class="mt-5 mb-6">
        <div class="card w-[100%] mx-auto">
            <Accordion value="0">
                <AccordionPanel v-for="item in filteredFaq" :key="item.id" :value="`${item.id}`"
                    class="flex flex-col border-b-[1px] border-[#F37335]">
                    <AccordionHeader
                        class="botao-pergunta w-full my-2 text-left text-[#F37335] flex items-center justify-between">
                        <span
                            class="text-[#F37335] text-[15px] flex-1 mr-2 pergunta font-segoe-ui-semibold me-[20px]">{{
                                item.pergunta }}</span>
                    </AccordionHeader>
                    <AccordionContent>
                        <p class="font-segoe-ui text-[16px] text-[#727376] mb-4" v-if="item.resposta">
                            {{ item.resposta }}
                        </p>
                        <p class="font-segoe-ui text-[16px] text-[#727376] mb-4" v-else>
                            {{ item.respostaPart1 }}
                            <a :href="item.respostaLinkHref" target="_blank"
                                class="text-[#68A74E] font-extrabold underline">
                                {{ item.respostaLinkText }}
                            </a>
                            {{ item.respostaPart2 }}
                        </p>
                    </AccordionContent>
                </AccordionPanel>
            </Accordion>
        </div>
    </div>
</div>
</template>
<script setup>
    import { ref, computed } from 'vue';
    import InputText from 'primevue/inputtext';
    import Accordion from 'primevue/accordion';
    import AccordionPanel from 'primevue/accordionpanel';
    import AccordionHeader from 'primevue/accordionheader';
    import AccordionContent from 'primevue/accordioncontent';



    const pergunta = ref('');
    const faq = [
        {
            id: 1,
            pergunta: "O que é o Hooy?",
            resposta: "O Hooy é uma plataforma digital que ajuda as pessoas a se conhecerem melhor e a viverem o momento presente com foco e atenção."
        },
        {
            id: 2,
            pergunta: "Para que ele serve?",
            resposta: "Ele serve para guiar as pessoas em uma jornada de autoconhecimento, ajudando-as a entender seus pensamentos, sentimentos e ações, e a enfrentar desafios emocionais."
        },
        {
            id: 3,
            pergunta: "Como usar o Hooy?",
            resposta: "Para usar o Hooy, basta acessar a plataforma digital, criar uma conta e começar a explorar suas funcionalidades. É como ter um guia pessoal para se conhecer melhor e viver com mais autenticidade."
        },
        {
            id: 4,
            pergunta: "Quais os benefícios do Hooy?",
            resposta: "Os benefícios do Hooy incluem a capacidade de superar desafios emocionais, construir uma versão mais consciente de si mesmo e cultivar a coragem para quebrar padrões automáticos. Ele também promove uma maior qualidade de vida ao ajudar as pessoas a viverem o momento presente com mais atenção e foco."
        },
        {
            id: 5,
            pergunta: "O que a plataforma oferece?",
            resposta: "A plataforma oferece uma variedade de recursos interativos para entrar em contato com seus pensamentos, sentimentos e ações. Além disso, estrutura esses elementos em forma de diário para ajudar os usuários a reconhecerem seus progressos no autoconhecimento, enquanto enfrentam seus desafios emocionais."
        },
        {
            id: 6,
            pergunta: "Como fazer o meu cadastro no Hooy?",
            resposta: "Para fazer o seu cadastro no Hooy, basta acessar o site, clicar em cadastrar, e preencher algumas informações básicas, como nome, e-mail, data de nascimento, gênero e criar uma senha. Depois de preencher os detalhes necessários, você enviará a solicitação de acesso. Em menos de 24 horas você estará pronto para começar a explorar a plataforma."
        },
        {
            id: 7,
            pergunta: "Como usar as ações disponíveis na plataforma?",
            resposta: "As ações disponíveis na plataforma são ferramentas interativas projetadas para te ajudar a se conectar consigo mesmo, com o momento presente e com suas relações interpessoais do dia a dia. Você pode acessar essas ações na plataforma e segui-las passo a passo para fazer pausas, reflexões, momentos de silêncio, além de acessar um conteúdo educacional que te permite aprender a nomear o que acontece no seu mundo interior e muito mais."
        },
        {
            id: 8,
            pergunta: "Porque eu preciso fazer o teste do Bem Estar de tempos em tempos?",
            resposta: "Baseado na fórmula da felicidade da psicologia positiva, ele permite avaliar seu bem-estar atual e acompanhar sua evolução ao longo do uso da plataforma. Ao fazer o teste do Bem Estar de tempos em tempos te ajudará a aumentar a sua consciência sobre seu bem-estar físico, emocional e mental. Podemos pensar nele como uma espécie de termômetro que te mostra como está, por exemplo, sua motivação, foco e concentração, humor, autoconfiança, etc. Isso ajuda a acompanhar o seu progresso ao longo do tempo e identificar áreas em que você pode precisar de mais apoio ou atenção."
        },
        {
            id: 9,
            pergunta: "Para que serve a ferramenta de respiração e como ela pode me ajudar?",
            resposta: "A ferramenta de respiração é uma técnica de relaxamento que pode ajudar a acalmar a mente e o corpo. Ela pode ser útil para reduzir o estresse, a ansiedade e promover uma sensação de calma e equilíbrio. Você pode usar essa ferramenta sempre que sentir a necessidade de relaxar e se concentrar no momento presente. Para administrar melhor aquilo que está se apresentando para você em sua mente ou corpo."
        },
        {
            id: 10,
            pergunta: "Como faço para aproveitar e registrar melhor na plataforma na aba de experiência pessoal?",
            resposta: "Para aproveitar ao máximo e registrar suas experiências pessoais na plataforma, você pode dedicar um tempo regularmente para explorar as diferentes ferramentas e recursos disponíveis. Isso inclui praticar as ações interativas, fazer o teste do Bem Estar, usar a ferramenta de respiração e registrar seus pensamentos e sentimentos em sua jornada pessoal. Quanto mais você se envolver com a plataforma e refletir sobre suas experiências, mais benefícios poderá obter do Hooy."
        },
        {
            id: 11,
            pergunta: "Por que usar a aba compromisso?",
            resposta: "A aba Compromisso permite que você defina quantas vezes por semana vai praticar ações de bem-estar e acompanha seu progresso na tela. Isso ajuda a incentivar a regularidade nas práticas de bem-estar, lembrando você de se cuidar e promover seu crescimento pessoal."
        },
        {
            id: 12,
            pergunta: "O que fazer quando não consigo acessar e ou me cadastrar na plataforma?",
            resposta: "Se você não consegue acessar ou se cadastrar na plataforma, existem algumas etapas que podem te ajudar: Verifique se está usando o e-mail e senha corretos. Tenha certeza de digitar seu e-mail e senha nos lugares certos. Veja se sua internet está funcionando bem. Se estiver fazendo tudo corretamente e mesmo assim não estiver conseguindo, entre em contato com a gente pelo WhatsApp para auxiliarmos. (aqui no WhatsApp colocar um link do Whats do celular final 0369, se não tiver me pede que te mando)"
        },
        {
            id: 13,
            pergunta: "O que é mindfulness? Como ele me ajuda nesse processo?",
            resposta: "Mindfulness, ou atenção plena, é uma prática que envolve estar consciente e presente no momento atual, sem julgamentos. Consiste em direcionar intencionalmente a atenção para as sensações, pensamentos e emoções presentes, sem se prender a preocupações sobre o passado ou o futuro. O mindfulness ajuda no processo de autoconhecimento ao permitir que você observe suas próprias experiências internas de forma mais objetiva e consciente. Isso pode ajudá-lo a identificar padrões de pensamento, emoções recorrentes e comportamentos automáticos, proporcionando uma maior clareza sobre si mesmo e suas necessidades. A base do Hooy é o mindfulness e a Psicologia Positiva."
        },
        {
            id: 14,
            pergunta: "Como eu faço pra encaixar o Hooy na minha rotina?",
            resposta: "Para encaixar o Hooy na sua rotina, podemos te sugerir algumas estratégias: Reserve um horário específico do dia para se dedicar ao uso do aplicativo, seja de manhã ao acordar, durante uma pausa no trabalho ou à noite antes de dormir. Estabeleça metas realistas e alcançáveis para o uso do Hooy, seja dedicando alguns minutos por dia ou realizando atividades específicas oferecidas pelo aplicativo. Utilize lembretes ou alarmes no seu dispositivo para lembrá-lo de usar o Hooy regularmente. Integre o Hooy em suas atividades diárias, como parte de sua rotina matinal ou antes de dormir, criando assim um hábito consistente. Experimente diferentes funcionalidades do aplicativo e descubra quais se encaixam melhor em sua rotina e necessidades pessoais. Priorize o autoconhecimento e o bem-estar mental, reconhecendo a importância de reservar tempo para cuidar de si mesmo."
        },
        {
            id: 15,
            pergunta: "O que significa estar presente no momento presente e como isso pode ser útil na minha rotina diária?",
            resposta: "Estar presente no momento presente significa focar totalmente naquilo que está acontecendo no aqui e agora, sem se preocupar com o passado ou o futuro. Isso é importante porque muitas vezes passamos muito tempo remoendo o que já aconteceu ou nos preocupando com o que ainda está por vir, perdendo assim a oportunidade de viver plenamente o presente. Quando estamos verdadeiramente presentes, podemos apreciar mais as pequenas coisas da vida, reduzir o estresse e a ansiedade, melhorar a qualidade das nossas interações e tomar decisões mais conscientes e assertivas. Na rotina diária, estar presente nos ajuda a realizar nossas tarefas com mais eficiência, a desfrutar dos momentos com mais intensidade e a cultivar uma sensação geral de bem-estar e contentamento."
        },
        {
            id: 16,
            pergunta: "O que é a respiração 6:3 na opção S.O.S?",
            resposta: "A técnica de respiração 6:3 é uma prática de respiração profunda que envolve uma inspiração prolongada seguida por uma expiração prolongada, com uma relação de seis segundos de inspiração para três segundos de expiração. Essa técnica de respiração é frequentemente utilizada para acalmar a mente e reduzir os níveis de estresse e ansiedade, pois ajuda a ativar o sistema nervoso parassimpático, responsável pelo relaxamento do corpo. Ao praticar o ciclo de respiração 6:3, os indivíduos podem alcançar um estado de calma e tranquilidade em momentos de tensão ou ansiedade."
        },
        {
            id: 17,
            pergunta: "O que é o ciclo emoções?",
            resposta: "O Ciclo Emoções da plataforma Hooy foi criado para ajudar você a entender e lidar melhor com suas emoções primárias. Emoções primárias, como alegria, tristeza, medo, raiva, nojo e amor, são respostas automáticas e instintivas a estímulos do ambiente. Elas são fundamentais porque: Nos Protegem: Emoções como medo e raiva são mecanismos de defesa que nos ajudam a responder rapidamente a situações de perigo, garantindo nossa sobrevivência. Guiam Nossas Ações: Emoções nos ajudam a tomar decisões rápidas e nos motivam a agir. Por exemplo, a alegria pode nos incentivar a repetir uma experiência positiva, enquanto a tristeza pode nos levar a buscar apoio. Facilitam a Comunicação: Expressamos nossas emoções de maneira natural, ajudando a comunicar nossas necessidades e sentimentos aos outros. Usar o Hooy para explorar o Ciclo Emoções oferece ferramentas práticas e reflexões que promovem um entendimento profundo do seu mundo emocional. Isso resulta em uma vida mais equilibrada, consciente e feliz."
        },
        {
            id: 18,
            pergunta: "O que é o ciclo presença?",
            resposta: "O Ciclo Presença da plataforma Hooy foi criado para ajudar você a cultivar a presença plena no seu dia a dia. A presença, ou mindfulness, é a prática de estar completamente atento e envolvido no momento presente, com uma atitude aberta e sem julgamentos. Importância da Presença: O Que É: Presença é estar consciente do aqui e agora, em vez de se perder em pensamentos sobre o passado ou o futuro. Para Que Serve: Serve para melhorar nossa capacidade de focar, reduzir o estresse e aumentar a apreciação das pequenas coisas da vida. Do Que Nos Protege: Nos protege da ansiedade e do excesso de ruminação mental, que podem levar a problemas de saúde mental e emocional. Importância no Autoconhecimento: Estar presente nos ajuda a nos conectar com nossas emoções e pensamentos reais, promovendo um maior autoconhecimento e compreensão de nossos padrões de comportamento. No Hooy, o Ciclo Presença ensina técnicas de mindfulness para ajudá-lo a integrar a prática da presença em sua rotina diária. Aprender e aplicar essas técnicas pode ser de extrema valia para seu bem-estar e qualidade de vida, proporcionando uma maior sensação de controle, paz interior e felicidade."
        },
        {
            id: 19,
            pergunta: "Para que serve a ferramenta perguntas para ir além?",
            resposta: "Com perguntas poderosas, essa ferramenta estimula a autorreflexão e a descoberta de novas perspectivas. Ela é especialmente útil para desbloquear momentos de paralisação, enfrentar dilemas e promover insights significativos. Ao usar essa ferramenta, você desenvolve um maior autoconhecimento e encontra soluções criativas para questões pessoais, melhorando seu bem-estar e crescimento pessoal. Além disso, você aprende a pensar por si mesmo e a formar suas próprias opiniões."
        },
        {
            id: 20,
            pergunta: "O que são os diários e como posso aproveitá-los melhor?",
            respostaPart1: "Os diários no Hooy são ferramentas digitais de escrita que permitem registrar suas emoções, pensamentos e experiências diárias. A importância da escrita reside em sua capacidade de promover o autoconhecimento e a reflexão pessoal. Em breve, você poderá fazer o download desses diários sempre que desejar, mantendo um registro permanente de sua jornada. Além disso, no Hooy, os diários são projetados para ajudá-lo a reconhecer e acompanhar seu progresso no autoconhecimento. Eles são direcionados para facilitar a identificação de padrões emocionais e comportamentais, proporcionando insights valiosos sobre seu desenvolvimento pessoal. Aproveite os diários para refletir regularmente e observar como suas práticas e reflexões contribuem para seu bem-estar e crescimento contínuo. Fale conosco pelo ",
            respostaLinkText: "WhatsApp clicando aqui",
            respostaLinkHref: "https://wa.me/5567992990369",
            respostaPart2: "."
        }
    ];

    const filteredFaq = computed(() => {
        if (!pergunta.value) {
            return faq;
        }

        return faq.filter(item => item.pergunta.toLowerCase().includes(pergunta.value.toLowerCase()));
    });
</script>

<style scoped></style>
