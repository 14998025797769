<template>
    <div class="content mt-[29px]">
        <h1
            class="font-bold-segoe text-center text-[26px] text-[#37A79F] leading-none mx-[58px]"
        >
            Perguntas para <br />
            ir Além
        </h1>
        <div class="logo-step flex items-center justify-center my-10">
            <img src="@/assets/icons/avatars/ask_to_go.png" alt="" />
        </div>
        <div class="my-8">
            <p
                class="text-[15px] text-center font-bold-segoe text-[#37A79F] mb-5 mx-[58px]"
            >
                Uma pergunta sempre expande suas possibilidades em qualquer
                contexto e situação.
            </p>
            <p
                class="font-segoe-ui text-[16px] text-[#727376] text-center mx-[30px]"
            >
                Está enfrentando um dilema nesse momento?! Clique no botão
                "avançar" e receba uma pergunta que pode ajudar você a expandir
                suas possibilidades.
            </p>
        </div>
        <div class="flex flex-col items-center justify-center mb-6">
            <router-link
                to="/perguntas/dilema"
                class="flex items-center justify-center font-bold-segoe bg-[#37A79F] outline-none border-none text-[#FFF] text-[14px] p-3 rounded-md w-[90%] mx-[50px]"
            >
                avançar
            </router-link>
        </div>
    </div>
</template>
<script setup>
import { usePerguntasParaIrAlemStore } from "@/stores/perguntasParaIrAlem.store";
import { onMounted } from "vue";

const perguntasStore = usePerguntasParaIrAlemStore();

const limparCampos = () => {
    perguntasStore.clear();
};

onMounted(() => {
    limparCampos();
});
</script>
