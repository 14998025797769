import axios from 'axios';
const API_URL = `${process.env.VUE_APP_API_URL}`;

export default {
    async getAskByIdAsk(idAsk) {
        if (!idAsk) {
            throw new Error('idAsk is required');
        }

        try {
            const url = `${API_URL}/ask/get-by-id-ask/${idAsk}`;
            const response = await axios.get(url);
            return response.data;
        }
        catch (error) {
            console.error('Erro ao obter dados:', error);
            throw error;
        }
    },
    async salvarReflexao(formulario) {
        if (!formulario || !formulario.questionId || !formulario.questionText || !formulario.response || !formulario.dilemma) {
            throw new Error('O formulário deve ser preenchido.');
        }

        try {
            const url = `${API_URL}/ask`;
            const token = localStorage.getItem("token");
            const config = { headers: { Authorization: token } };
            const response = await axios.post(url, formulario, config);

            if (response.status === 200 && response.data.user.asktoGo) {
                const ultimoItem = response.data.user.asktoGo[response.data.user.asktoGo.length - 1];
                return ultimoItem;
            }

            return null;
        }
        catch (error) {
            console.error('Erro ao salvar dados:', error);
            throw error;
        }
    },
    async obterReflexao(idAsk) {
        if (!idAsk) {
            throw new Error('O id deve ser informado.');
        }

        try {
            const url = `${API_URL}/ask/get-by-id-ask/${idAsk}`;
            const response = await axios.get(url);

            if (response.status === 200 && response.data) {
                return response.data;
            }

            return null;
        }
        catch (error) {
            console.error('Erro ao salvar dados:', error);
            throw error;
        }
    },
    async adicionarNovaPerspectiva(formulario) {
        if (!formulario || !formulario.questionId || !formulario.reflectionText) {
            throw new Error('O formulário deve ser preenchido.');
        }

        try {
            const url = `${API_URL}/ask/add-new-perspective`;
            const token = localStorage.getItem("token");
            const config = { headers: { Authorization: token } };
            const response = await axios.post(url, formulario, config);

            if (response.status === 200 && response.data) {
                return response.data;
            }

            return null;
        }
        catch (error) {
            console.error('Erro ao salvar dados:', error);
            throw error;
        }
    },
}
