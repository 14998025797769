<template>
<h5 class="font-bold-segoe text-center text-[15px] text-[#37A79F] leading-none mx-[58px] my-8">
    Pergunta para ir além
</h5>
<div class="flex justify-center items-center mx-[1rem] my-12 ">
    <h1 id="ask" class="text-center font-bold-segoe text-[26px] text-[#37A79F] h-50 w-30 text-wrap mx-4">
        {{ currentQuestion }}
    </h1>
</div>
<div class="my-8">
    <p class="font-segoe-ui text-[16px] text-[#727376] text-center mx-[30px]">
        Essa pergunta desafia seus padrões de pensamento e incentiva uma nova perspectiva sobre o seu dilema. Ela não
        busca respostas rápidas, mas um olhar mais profundo sobre a situação.
    </p>
</div>
<!-- Textarea -->
<FormKit type="textarea" name="questao" placeholder="Digite sua reflexão" :classes="config_textarea" v-model="response"
    :value="response" cols=50 rows=5 />
<!-- Botão -->
<div class="mb-6">
    <FormKit type="button" :classes="config_submit" label="outra pergunta" @click="randomAsk" />
    <FormKit type="submit" :classes="config_submit" label="enviar reflexão" @click="confirmReflection" />
</div>

<ModalAlert v-model="show" title="" @confirm="confirm" @click-outside="clickOutside" :okButtonText="textoBotaoOk"
    ok-button-class="flex items-center justify-center font-bold-segoe bg-[#37A79F] outline-none border-none text-[#FFF] text-[14px] p-[2px] w-[100%] m-auto h-[41px] rounded-[13px]"
    modal-border-color="border-[#37A79F]">
    <p v-html="tituloModal" class="font-bold-segoe text-[15px] text-[#37A79F] text-center mb-6"></p>
</ModalAlert>
</template>

<script setup>
    import { ref, inject, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import ModalAlert from '@/components/Modals/ModalAlert.vue';
    import { usePerguntasParaIrAlemStore } from '@/stores/perguntasParaIrAlem.store';
    import askService from '@/services/ask.service';

    const router = useRouter();
    const config_textarea = {
        input:
            "w-[90%] m-3 bg-[#DDF6F4] rounded-[13px] border border-[#37A79F]  outline-none p-2 placeholder-[#37A79F] placeholder-[8px] placeholder:text-[11px] placeholder:italic text-[#37A79F] mb-[5%]",
        inner: "flex items-center justify-center",
    };
    const config_submit = {
        input:
            "w-[90%] flex items-center justify-center font-bold-segoe bg-[#37A79F] outline-none border-none text-[#FFF] text-[14px] mb-[3%] p-3 rounded-md w-[90%] ",
        wrapper: "flex items-center justify-center",
    };

    const axios = inject('axios');
    const questions = ref([]);
    const currentQuestion = ref("");
    const currentQuestionId = ref(null);
    const response = ref("");
    let previousIndex = null;
    const show = ref(false);
    const tituloModal = ref('Parabéns! Cada reflexão é um passo importante no seu autoconhecimento.');
    const textoBotaoOk = ref('sua reflexão');
    const perguntasStore = usePerguntasParaIrAlemStore();

    const getAsk = async () => {
        try {
            const response = await axios.get('/ask');
            questions.value = response.data.ask;
            getRandomQuestion();
        } catch (error) {
            console.log('ERROR: ', error);
        }
    }

    const getRandomQuestion = () => {
        if (questions.value.length) {
            let randomIndex;
            do {
                randomIndex = Math.floor(Math.random() * questions.value.length);
            } while (randomIndex === previousIndex);

            currentQuestion.value = questions.value[randomIndex].textContent;
            currentQuestionId.value = questions.value[randomIndex]._id;
            previousIndex = randomIndex;
            response.value = "";
        }
    }

    const confirm = () => {
        if (!response.value) {
            show.value = false;
            return;
        }

        submitReflection();
    };

    const clickOutside = () => {
        show.value = false;
    };

    const randomAsk = () => {
        getRandomQuestion();
    }

    const confirmReflection = () => {
        if (!response.value) {
            textoBotaoOk.value = 'Ok';
            apresentarModal('Por favor, digite sua reflexão.');
            return;
        }

        textoBotaoOk.value = 'sua reflexão';
        apresentarModal('Parabéns! Cada reflexão é um passo importante no seu autoconhecimento.');
    }

    const submitReflection = async () => {
        if (!formularioValido()) {
            apresentarModal('Todos os campos devem ser preechidos. Retorne ao início e tente novamente.');
            return;
        }

        try {

            const formulario = {
                questionId: currentQuestionId.value,
                questionText: currentQuestion.value,
                response: response.value,
                dilemma: perguntasStore.getDilema
            };

            const ultimoItem = await askService.salvarReflexao(formulario);

            if (ultimoItem) {
                limparCampos();
                router.push(`/perguntas/resposta/${ultimoItem._id}`);
            }
        }
        catch (error) {
            console.log('ERROR: ', error);
        }
    }

    const apresentarModal = (message) => {
        tituloModal.value = message;
        show.value = true;
    };

    const limparCampos = () => {
        response.value = "";
        perguntasStore.clear();
    }

    const formularioValido = () => {
        return perguntasStore.getDilema && currentQuestionId.value && currentQuestion.value && response.value;
    }

    onMounted(() => {
        getAsk();
    });
</script>
