<template>
<div class="content mt-[29px]" v-if="loading">
    <div id="header-perfil" class="flex flex-col items-center justify-center">
        <img src="@/assets/icons/avatars/perfil.png" alt="Avatar Perfil">
        <h1 class="font-bold-segoe text-center text-2xl text-[#F37335] leading-none mx-[58px] mb-3">
            Dados Autênticos
        </h1>
        <p class="font-segoe-ui text-[16px] text-[#727376] mb-5 mx-[58px]">
            Veja abaixo suas informações cadastrais:
        </p>
    </div>
    <div class="mx-[28px]">

        <!-- Formulário -->

        <FormKit :disabled="!editar" type="text" name="name" id="name" label="Nome Completo"
            validation-visibility="live" validation-label="Nome Completo"
            :validation-messages="{ required: 'Nome completo é obrigatório' }" :classes="style_config"
            v-model="user.name" />

        <FormKit :disabled="!editar" type="date" name="birthday" id="birthday" label="Data de Nascimento"
            :validation="[['date_between', antes, hoje]]" :min="antes" :max="hoje" validation-visibility="live"
            validation-label="Data de Nascimento"
            :validation-messages="{ required: 'Data é obrigatório', date_between: `A data deve ser entre os anos ${antes.getUTCFullYear()} e ${hoje.getUTCFullYear()}.` }"
            :classes="style_config" v-model="nascimento" />

        <FormKit :disabled="!editar" type="select" name="gender" id="gender" label="Gênero" :classes="style_config"
            :options="[
                { label: 'Selecione um gênero', attrs: { disabled: true } },
                { label: 'Masculino', value: 'male' },
                { label: 'Feminino', value: 'female' },
                { label: 'Prefiro não informar', value: 'other' }
            ]" v-model="gender" />

        <FormKit :disabled="!editar" type="tel" name="whatsapp" id="whatsapp" label="Celular | Whatsapp"
            :classes="style_config" v-maska:[option]="maskedValue" placeholder="(DD) 99999-9999" v-model="whatsapp" />

        <div class="mb-6">
            <FormKit v-if="!editar" type="button" label="Editar" name="editar" id="editar" :classes="{
                wrapper: 'flex flex-col',
                input: 'font-bold-segoe  bg-[#F37335] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[15px] my-6'
            }" @click="() => edit()" />
            <FormKit v-if="editar" type="submit" label="Salvar" name="submit" id="submit" :classes="{
                wrapper: 'flex flex-col',
                input: 'font-bold-segoe  bg-[#68A74E] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[15px] my-6',
            }" @click="salvar" />
        </div>
    </div>


</div>
</template>

<script setup>
    import { ref, inject, reactive } from 'vue';
    import { vMaska } from 'maska';
    import moment from 'moment'; // Importe o Moment.js

    const axios = inject('axios');
    const loading = ref(false);
    const user = ref('');
    const userName = ref('');
    const nascimento = ref('');
    const gender = ref('');
    const whatsapp = ref('');
    const editar = ref(false)

    const style_config = {
        label: 'font-segoe-ui text-sm text-[#F37335] font-bold',
        input: 'font-[Segoe UI] p-[10px] text-[#727376] bg-[#FEEEE2] outline-none w-[100%] border rounded-[15px]',
        messages: 'text-[13px] text-[#727376]',
        wrapper: 'flex flex-col',
        outer: 'my-2',

    };
    const maskedValue = reactive({});
    const option = {
        mask: "(##) #####-####"
    }

    const antes = new Date("1924-01-01")
    const date = Date.now()
    const hoje = new Date(date)

    const apiUser = async () => {
        try {
            const userBaseUrl = `${process.env.VUE_APP_API_URL}/user`;
            await axios.get(`${userBaseUrl}/perfil`)
                .then(({ data }) => {
                    if (data.success) {
                        console.log('DATA API: ', data)
                        user.value = data.user;
                        userName.value = data.user.name.split(' ')[0];
                        // Use moment.utc() para interpretar a data em UTC
                        nascimento.value = moment.utc(data.user.birthday).format('YYYY-MM-DD');
                        gender.value = data.user.gender;
                        whatsapp.value = data.user.cel;

                        loading.value = true
                    }
                })
                .catch(err => console.log('Erro CATCH da API: ', err))
                .finally(() => loading.value = true);
        } catch (error) {
            console.log('ERRO do TRY: ', error)
        }

    }

    const edit = () => {
        editar.value = true;
    }

    const salvar = async () => {
        const userBaseUrl = `${process.env.VUE_APP_API_URL}/user`;
        await axios.put(`${userBaseUrl}/editar-perfil`, {
            name: userName.value,
            birthday: nascimento.value,
            gender: gender.value,
            cel: whatsapp.value,
        })
            .then(({ data }) => {
                console.log('DATA POST: ', data)
                editar.value = false;
            })
    }


    try {
        apiUser();
    } catch (error) {

    }




</script>



<style scoped></style>
