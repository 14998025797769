<template>
  <VueFinalModal class="flex justify-center items-center"
    content-class="vfm flex flex-col max-w-xl mx-4 p-4 border rounded-lg space-y-2 bg-[#F37335] text-[#FEEEE2]"
    @update:model-value="val => emit('update:modelValue', val)" @click="handleModalClick($event)">
    <h1 class="text-xl font-bold">
      {{ title }}
    </h1>
    <slot />
    <button class="font-bold-segoe mt-1 m-auto p-2 px-4 border bg-[#FEEEE2] text-[#F37335] rounded-lg"
      @click="emit('confirm')">
      OK
    </button>
  </VueFinalModal>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { VueFinalModal } from 'vue-final-modal';

// Define as propriedades do componente
const props = defineProps({
  title: String,
});

// Define os eventos emitidos pelo componente
const emit = defineEmits(['update:modelValue', 'confirm', 'click-outside']);

// Função para lidar com o clique dentro do modal
const handleModalClick = (event) => {
  // Verifica se o clique ocorreu fora do conteúdo do modal
  if (!event.target.closest('vfm')) {
    // Emite o evento click-outside
    emit('click-outside');
  }
};
</script>
