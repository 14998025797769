<template>
<div class="mx-[28px] mb-5" v-if="loading">
    <div class="text-center my-4">
        <h1 class="font-bold-segoe text-[#FD7862] text-[26px] mb-3 leading-7">Reflexões para<br />Ir Além</h1>
        <p class="font-segoe-ui text-[16px] text-[#727376] text-center mx-[5px]">
            Registrar suas percepções não apenas fortalece seu
            entendimento sobre si mesmo, mas também oferece
            clareza para enfrentar novas situações com mais
            sabedoria e consciência
        </p>
    </div>

    <div id="lista-de-perguntas" class="mt-5 mb-6">
        <div class="card w-[100%] mx-auto">
            <Accordion value="0">
                <AccordionPanel v-for="(item, index) in formattedReflection" :key="index" :value="`${index}`"
                    class="flex flex-col border-b-[1px] border-[#FD5445]">
                    <AccordionHeader
                        class="botao-pergunta w-full my-2 text-left text-[#FD5445] flex items-center justify-between">
                        <span
                            class="font-segoe-ui-semibold text-[16px] text-[#FD5445] flex-1 mr-2 pergunta me-[20px]">{{
                                item.textContent }}</span>
                    </AccordionHeader>
                    <AccordionContent>
                        <p class="font-segoe-ui text-[16px] text-[#727376] mb-4 " v-if="item.textAnswer">
                            R.: {{ item.textAnswer }}
                            <br>
                            <small class="font-segoe-ui text-[14px] text-[#727376] my-10"> respondida em {{
                                item.formattedDate
                                }}</small>
                        </p>
                    </AccordionContent>
                </AccordionPanel>
            </Accordion>
        </div>
    </div>


</div>
</template>

<script setup>
    import { onMounted, computed } from 'vue';
    import { usePerfil } from '@/modules/progresso/composables/usePerfil';

    import Accordion from 'primevue/accordion';
    import AccordionPanel from 'primevue/accordionpanel';
    import AccordionHeader from 'primevue/accordionheader';
    import AccordionContent from 'primevue/accordioncontent';

    const { loading, fetchPerfil, askToGo } = usePerfil();

    const convertIsoToCustomFormat = (isoString) => {
        const date = new Date(isoString);

        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        };

        return new Intl.DateTimeFormat('pt-BR', options).format(date).replace(',', '');
    };

    const formattedReflection = computed(() => {
        return askToGo.value.map(data => ({
            ...data,
            formattedDate: convertIsoToCustomFormat(data.createdAt),
        }));
    });

    onMounted(() => {
        fetchPerfil();
    });
</script>

<style scoped></style>
