<template>
<VueFinalModal class="flex justify-center items-center"
    :content-class='modalStyle' @update:model-value="val => emit('update:modelValue', val)"
    @click="handleModalClick($event)">
    <h1 class="text-xl font-bold text-center " :class="titleTextColor">
        {{ title }}
    </h1>
    <div class="text-[#727376] text-center py-4 font-size">
        <slot></slot>
    </div>
    <button :class="props.okButtonClass" @click="emit('confirm')">
        <span>{{ okButtonText }}</span>
    </button>
    <button v-if="showCancel" :class="cancelButtonClass" @click="emit('cancel')">
        {{ cancelButtonText }}
    </button>
</VueFinalModal>
</template>

<script setup>
    import { defineProps, defineEmits, ref } from 'vue';
    import { VueFinalModal } from 'vue-final-modal';

    const props = defineProps({
        title: String,
        showCancel: {
            type: Boolean,
            default: false,
        },
        okButtonClass: {
            type: String,
            default: 'font-bold-segoe mt-1 m-auto p-2 px-4 border bg-[#F37334] text-[#FFF] rounded-lg',
        },
        okButtonText: {
            type: String,
            default: 'OK',
        },
        cancelButtonClass: {
            type: String,
            default: 'font-bold-segoe mt-1 m-auto p-2 px-4 border bg-[#F37334] text-[#FFF] rounded-lg',
        },
        cancelButtonText: {
            type: String,
            default: 'Cancelar',
        },
        titleTextColor: {
            type: String,
            default: 'text-[#F37334]',
        },
        modalBorderColor: {
            type: String,
            default: 'border-[#F37334]',
        },
    });

    const modalStyle = ref(`vfm flex flex-col max-w-xl mx-4 p-4 rounded-lg space-y-2 bg-[#F8F8F8] w-[90%] border-[1px] ${props.modalBorderColor}`);

    const emit = defineEmits(['update:modelValue', 'confirm', 'click-outside', 'cancel']);

    const handleModalClick = (event) => {
        if (!event.target.closest('vfm')) {
            emit('click-outside');
        }
    };
</script>

<style scoped>
    .font-size {
        font-size: 15px;
    }
</style>
