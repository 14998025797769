<script setup>
    import Navbar from '@/components/Navbar/Navbar.vue';
    import BannerInicial from '@/components/Banners/BannerInicial.vue';
    import FooterInicial from '@/components/Footers/FooterInicial.vue';
    const red = 'red';
</script>
<template>
<div class="flex flex-col min-h-screen">
    <header>
        <navbar></navbar>
        <banner-inicial :logoHooy="red" :borderColor="red"></banner-inicial>
    </header>
    <main class="flex-grow">
        <router-view />
    </main>
    <footer class="mt-auto">
        <footer-inicial :iconVoltar="red" :miniLogo="red"></footer-inicial>
    </footer>
</div>
</template>
