<template>
<div class="flex items-center justify-center">
    <form @submit.prevent="login">
        <div class="email-input w-[271px] mb-[25px] flex bg-[#F9E1BD] text-[#F37335] p-3 rounded-xl ">
            <label for="email" class="font-bold-segoe mr-2">e-mail</label>
            <input type="email" required autocomplete="off" name="email" v-model="form.email"
                placeholder="Digite seu e-mail" class="font-normal bg-transparent placeholder-[#F37335] outline-none">
        </div>
        <PasswordInput v-model="form.password" @keydown.enter.prevent="login" />
        <forgot-password />
        <div class="actions mt-[15%] mb-[15%]">
            <router-link to="/cadastro">
                <button
                    class="font-bold-segoe text-[14px] bg-[#FFCC2A] w-[95px] border-none rounded-full text-[#F37335] p-[7px] mr-[20px]">
                    Cadastrar
                </button>
            </router-link>
            <button type="submit"
                class="font-bold-segoe text-[14px] bg-[#FFCC2A] w-[95px] border-none rounded-full text-[#F37335] p-[7px]">
                Entrar
            </button>
        </div>
    </form>
</div>
</template>

<script setup>
import { defineEmits } from 'vue';
import PasswordInput from '@/modules/login/components/PasswordInput.vue';
import { useForm } from '@/modules/login/composables/useForm';
import ForgotPassword from '../components/ForgotPassword.vue';

const emit = defineEmits(['show-modal', 'loading']);

const showModal = (message) => {
    emit('show-modal', message);
};

const setLoading = (isLoading) => {
    emit('loading', isLoading);
};

const { form, login } = useForm(showModal, setLoading);
</script>

<style scoped>
.actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
</style>
