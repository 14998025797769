<template>
<div class="mx-[28px] flex flex-col items-center justify-center">
    <div class="flex flex-col items-center justify-center">
        <h1 class="text-[#0080C5] text-[26px] text-center font-bold-segoe leading-6 my-3">
            Que bom ver você <br />
            por aqui!
        </h1>
        <img src="@/assets/icons/avatars/frequencia.png" alt="" class="my-2">
        <h4 class="text-[#0080C5] text-[15px] text-center font-bold-segoe leading-6 mx-[50px] mb-2">
            Vamos construir a sua rotina
            de autoconhecimento?
        </h4>
        <p class="font-segoe-ui text-[16px] text-[#727376] text-center mx-[10px]">
            Me conte com que frequência
            você pretende praticar as ações de bem-estar?
        </p>
    </div>
    <div class="w-[100%] flex flex-col gap-2 items-center justify-center my-5 mb-6">
        <div class="w-[100%] bg-[#21DDFF] rounded-md p-[0.7rem] text-white text-center text-[14px] font-bold-segoe cursor-pointer"
            @click="setFrequence(1)">
            1 ação por semana
        </div>
        <div class="w-[100%] bg-[#01D8FF] rounded-md p-[0.7rem] text-white text-center text-[14px] font-bold-segoe cursor-pointer"
            @click="setFrequence(2)">
            2 ações por semana
        </div>
        <div class="w-[100%] bg-[#00C3E2] rounded-md p-[0.7rem] text-white text-center text-[14px] font-bold-segoe cursor-pointer"
            @click="setFrequence(3)">
            3 ações por semana
        </div>
        <div class="w-[100%] bg-[#00A9E2] rounded-md p-[0.7rem] text-white text-center text-[14px] font-bold-segoe cursor-pointer"
            @click="setFrequence(4)">
            4 ações por semana
        </div>
        <div class="w-[100%] bg-[#0080C5] rounded-md p-[0.7rem] text-white text-center text-[14px] font-bold-segoe cursor-pointer"
            @click="setFrequence(5)">
            5 ações por semana
        </div>
        <div class="w-[100%] bg-[#0054A4] rounded-md p-[0.7rem] text-white text-center text-[14px] font-bold-segoe cursor-pointer"
            @click="setFrequence(6)">
            6 ações por semana
        </div>
        <div class="w-[100%] bg-[#003484] rounded-md p-[0.7rem] text-white text-center text-[14px] font-bold-segoe cursor-pointer"
            @click="setFrequence(7)">
            7 ações por semana
        </div>
    </div>
</div>
</template>

<script setup>
    import { useCompromisso } from '@/modules/compromisso/composables/useCompromisso';

    const { setFrequence } = useCompromisso();
</script>

<style scoped></style>
